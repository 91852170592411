import React from "react"
import { Link } from "gatsby"
import { Card, CardBody, CardTitle, CardLink, CardImg } from "shards-react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import chord_preview from "../images/chord_selector_preview.png"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h1>Hi y'all</h1>
    <p>Welcome to the random prototypes and side projects I put together in my spare time. Keep in mind that things might be a little rough or buggy, but let me know if you find any issues!</p>.
    <Link to="/chord-finder">
    <Card style={{ maxWidth: `800px` }}>
      <CardBody>
        <CardTitle>Chord Finder</CardTitle>
        <p>
          A tool to help guiitar players explore chords and scales
        </p>
      </CardBody>
      <CardImg bottom src={chord_preview} style={{ paddingLeft: `1.875rem`, paddingRight: `1.875rem` }} />
    </Card>
    </Link>
  </Layout>
)

export default IndexPage
